<template>
  <div>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">
              Lixeira de Clientes
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Lixeira de Clientes
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col" style="text-align: left;">
                      CNPJ
                    </th>
                    <th scope="col" class="coluna-lixeira-restaurar" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.nome)"
                    />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.cnpj)"
                    />
                     <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="restore(item.id)"
                      >
                        Restaurar
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import Swal from "sweetalert2";
import store from "@/store";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
  },
  data() {
    return {
      showLeftMenu: true,
      id: null,
      index: -1,
      item: {
        id: null,
        nome: "",
        razaoSocial: "",
        cnpj: "",
        telefone: "",
        responsavel: {
          nome: "",
          telefone: "",
          user: {
            email: "",
            senha: "",
          },
        },
      },
      items: [],
      filter: "",
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (
        !this.item.nome ||
        this.item.nome == "" ||
        !this.item.razaoSocial ||
        this.item.razaoSocial == "" ||
        !this.item.cnpj ||
        this.item.cnpj == "" ||
        !this.item.telefone ||
        this.item.telefone == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    restore(id) {
      // /api/professors/{id}/recovery
      this.$api
        .put("clientes/" + id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Cliente restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function() {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deletar(index) {
      console.log(index);
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção dessa instituição?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api
            .delete("clientes/" + index)
            .then(() => {
              this.$root.$emit("Spinner::hide");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Cliente removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function() {
                location.reload();
              }, 1000);
              // this.loadData();
              // window.location.reload()
            })
            .catch((error) => {
                console.log(error.response.data.detail)
              Swal.fire({
                position: "center",
                icon: "error",
                title: error.response.data.detail,
                showConfirmButton: false,
                timer: 1500,
              });
            });
          this.$root.$emit("Spinner::hide");
        }
      });
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("clientes")
        .then((response) => {
          console.log(response)
          response.data.forEach((res) => {
            if(res.trashAt != null){
              this.items.push(res)
            }
          })
          // this.items = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.$root.$emit("Spinner::hide");
        });
    },
  }
};
</script>

<style>
.vm {
  max-width: 900px;
}
</style>
